@tailwind base;
// @tailwind components;
@tailwind utilities;

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "src/color.scss";
@import "src/client.style.rewrite.scss";

@layer base {
  html,
  body {
    // @apply h-full;
  }
}

html {
  font-size: 16px;
}

#imgoingcalendar-wrapper,
.cdk-overlay-container,
.ig-modal-shadow {
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  .mdc-notched-outline__trailing {
    border-left: none;
  }

  .mdc-notched-outline__notch {
    border-right: none;
    // width: calc(103px * var(--mat-mdc-form-field-floating-label-scale, 0.75) + 9px) !important;
  }

  button[mat-menu-item] {
    border-radius: 0;
    padding: 0 16px;
  }

  .mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: #41abf3;
    --mdc-slider-focus-handle-color: #41abf3;
    --mdc-slider-hover-handle-color: #41abf3;
    --mdc-slider-active-track-color: #41abf3;
    --mdc-slider-inactive-track-color: #41abf3;
    --mdc-slider-with-tick-marks-active-container-color: #fff;
    --mdc-slider-with-tick-marks-inactive-container-color: #41abf3;
    --mat-mdc-slider-ripple-color: #41abf3;
    --mat-mdc-slider-hover-ripple-color: rgba(103, 58, 183, 0.05);
    --mat-mdc-slider-focus-ripple-color: rgba(103, 58, 183, 0.2);
  }

  .mdc-circular-progress--indeterminate
    .mdc-circular-progress__circle-right
    .mdc-circular-progress__indeterminate-circle-graphic,
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #41abf3;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .mdc-button[ng-reflect-color="warn"] .mat-mdc-button-touch-target {
    background-color: #f44336;
  }

  .mdc-button .mat-mdc-button-touch-target {
    background-color: $primary-color;
    height: 36px;
    border-radius: 4px;
  }

  .mdc-button.mat-flat-button .mat-mdc-button-touch-target {
    border-radius: 0;
  }

  .mdc-button.mat-primary[disabled] .mat-mdc-button-touch-target,
  button[disabled] .mat-mdc-button-touch-target {
    background-color: lightgray;
  }

  mat-datepicker-content {
    .mdc-button .mat-mdc-button-touch-target {
      background-color: transparent;
    }
  }

  button {
    font-family: inherit;

    .mdc-button__label {
      letter-spacing: normal;
    }
  }

  .filter-range {
    .mdc-button .mat-mdc-button-touch-target {
      height: 36px;
    }
  }

  .ig-filter-range-tx {
    .ngx-slider-pointer {
      transform: translateX(-11px) !important;
    }
  }
}

.ig-attraction-description-generator-menu {
  --mdc-checkbox-state-layer-size: 34px;

  .mat-mdc-checkbox-touch-target {
    width: 34px;
    height: 34px;
  }
}

.ig-iti-att-editor {
  max-width: 480px;
  width: 480px;

  textarea {
    height: 16vh !important;
    min-height: 16vh !important;
    padding-right: 12px;
  }

  .mdc-text-field {
    padding-right: 0 !important;
  }
}

.ig-iti-desc-editor {
  textarea {
    height: 300px !important;
  }
}

.ig-tp-note-edit {
  textarea {
    height: 150px !important;
  }
}

.mat-mdc-menu-panel.ig-textarea {
  max-width: 380px;
  width: 380px;
  overflow: hidden;

  .ig-field {
    width: 100%;

    .mat-mdc-form-field-flex {
      height: 150px;
      overflow: auto;
    }

    textarea {
      overflow: hidden;
    }
  }
}

.mat-mdc-menu-panel.ig-menu-3 {
  max-width: 380px;
  width: 380px;
  overflow: auto;
}

.mat-mdc-menu-panel.ig-menu-80p {
  max-width: 80%;
  width: 80%;
  overflow: auto;
}

.ig-color-picker-shadow {
  color: white;
  text-shadow: 1px 1px 1px #000000, 1px 1px 1px #000000;
}

@media (max-width: 1080px) {
  .ig-iti-details-modal {
    .mat-mdc-dialog-container {
      padding: 0;
    }
  }
}

.ig-iti-create {
  max-width: unset !important;
  width: 500px;

  .ig-field {
    width: 100%;
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  .mdc-label {
    color: #52525b;
  }

  .mat-mdc-form-field-hint {
    color: red;
  }

  textarea {
    height: 300px !important;
  }
}

.ig-iti-upload {
  max-width: unset !important;
  width: 500px;

  .ig-field {
    width: 100%;
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  .mdc-label {
    color: #52525b;
  }

  .mat-mdc-form-field-hint {
    color: red;
  }
}

.ig-width-fit {
  max-width: fit-content !important;
}

.ig-text-color-edit {
  width: 500px;
  max-width: 500px !important;

  mat-form-field {
    width: 100%;
  }
}

.ig-iti-details-style-menu {
  max-width: unset !important;
  width: 300px;
}

.ig-rotate-180 {
  position: relative;
  transform-style: preserve-3d;
  animation: rotate-reverse-180 1.2s cubic-bezier(0.66, -0.47, 0.33, 1.5) forwards;
  perspective: 1000px;
}

@keyframes rotate-reverse-180 {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.ig-rotate-360 {
  position: relative;
  transform-style: preserve-3d;
  animation: rotate-reverse-360 2s cubic-bezier(0.66, -0.47, 0.33, 1.5) forwards;
  perspective: 1000px;
}

@keyframes rotate-reverse-360 {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0);
  }
}

:root {
  --mdc-protected-button-label-text-color: white;
  --mdc-filled-button-label-text-color: white;
  --mdc-typography-body1-font-size: 14px;
  --mdc-circular-progress-active-indicator-color: #41abf3;
  --mdc-protected-button-container-color: #41abf3;
  --mdc-typography-subtitle1-font-size: 14px;
  --mdc-theme-primary: rgb(113, 121, 174);
  --mdc-theme-text-primary-on-background: rgb(75 85 99);

  --default-btn-background-color: #5cbcff;
  --event-btn-background-color: #23c6a0;
  --place-btn-background-color: #eec30d;
  --pinned-event-border-color: #23c6a0;
  --detail-btn-background-color: #11a07f;
  --sidebar-underline-color: #41abf3;

  --checkmark-color: #7ac142;

  --scrollbar-color: rgba(0, 0, 0, 0.1);
  --scrollbar-track-bg: rgba(0, 0, 0, 0.05);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #41abf3;
}

.root-event-btn-bg {
  background-color: var(--event-btn-background-color);
}

.root-detail-btn-bg {
  background-color: var(--detail-btn-background-color);
}

.cdk-overlay-container {
  z-index: 999999;

  * {
    font-family: inherit;
  }

  .mat-mdc-menu-panel.ig-nested-menu {
    min-width: 200px !important;
    max-height: 500px;
  }

  .mat-mdc-menu-panel.ig-accordion-menu {
    min-width: 200px;
  }

  button.mat-mdc-menu-item {
    color: rgb(0, 0, 0, 0.87);

    &:hover {
      color: rgb(0, 0, 0, 0.87);
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
    background: var(--scrollbar-color);
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.05);
  }
}

// for Google address autocomplete
html {
  body {
    .pac-container {
      z-index: 10000000 !important;
      position: fixed !important;
    }
  }
}

.ig-im {
  .ig-ai-modal {
    .mdc-form-field > label {
      color: #eee;
    }
  }
}

.ig-modal {
  font-size: 16px;

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 8px;
    border-bottom: 2px solid $share-email;
  }

  .tip {
    padding: 12px 0;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
  }

  .action-btns {
    display: flex;
    float: right;
    margin-top: 24px;

    ig-button,
    .cancel-btn {
      min-width: 140px;
    }

    .cancel-btn {
      line-height: 40px;
      cursor: pointer;
      text-align: center;
    }

    button {
      width: 100%;
    }
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 1rem;
    cursor: pointer;
  }
}

.ig-modal-w-70p {
  width: 70% !important;
  max-width: 70% !important;

  mat-dialog-container {
    max-width: unset;

    .mdc-dialog__surface {
      overflow: hidden;
      padding: 32px;
    }
  }
}

.ig-modal-w-80p {
  width: 80% !important;
  max-width: 80% !important;

  mat-dialog-container {
    max-width: unset;

    .mdc-dialog__surface {
      overflow: hidden;
      padding: 32px;
    }
  }
}

.ig-modal-w-90p {
  width: 90% !important;
  max-width: 90% !important;

  mat-dialog-container {
    max-width: unset;

    .mdc-dialog__surface {
      overflow: hidden;
      // padding: 32px;
    }
  }
}

.ig-modal-w-full {
  position: absolute !important;
  top: 70px;
  left: 0;
  width: 100% !important;
  max-width: 100% !important;
  height: calc(100% - 70px);

  mat-dialog-container {
    background-color: #dee5ea;
    overflow-y: auto;
    padding: 16px;

    & * {
      font-family: inherit;
    }

    .mat-mdc-dialog-surface {
      background-color: #dee5ea;
      overflow: hidden;
    }

    ig-button {
      .mdc-button.mat-primary,
      .mat-mdc-button-touch-target {
        height: 34px;
      }
    }
  }
}

.ig-transparent-modal {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;

  mat-dialog-container {
    background: transparent;

    .mdc-dialog__container,
    .mdc-dialog__surface {
      background: transparent;
    }
  }
}

.map-container {
  width: 100% !important;
  height: 100% !important;

  .gm-style-iw {
    // max-width: 225px !important;
    // max-height: 225px !important;
    // min-height: 225px !important;
    padding: 0;
    padding-top: 0 !important;
  }

  .gm-style-iw-d {
    // max-width: 225px !important;
    // max-height: 225px !important;
    // min-height: 225px !important;
    overflow: hidden !important;
    padding: 0;
  }

  .gm-style img {
    max-width: none;
  }

  .gm-style-iw-chr {
    height: 0 !important;
  }
}

@media (max-width: 1560px) {
  .map-container {
    width: 100% !important;
    height: 100% !important;

    .gm-style-iw {
      // max-width: 180px !important;
      // max-height: 180px !important;
      // min-height: 180px !important;
      padding: 0;
    }

    .gm-style-iw-d {
      // max-width: 180px !important;
      // max-height: 180px !important;
      // min-height: 180px !important;
      overflow: hidden !important;
      padding: 0;
    }

    .gm-style img {
      max-width: none;
    }
  }
}

.ig-pinned {
  outline: 4px solid var(--pinned-event-border-color);

  &:hover {
    outline: unset;
  }
}

.ig-hide {
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;

  * {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
}

.ig-border-spinner {
  --border-size: 4px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      transparent,
      transparent 50%,
      transparent
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 20%,
      var(--pinned-event-border-color),
      var(--pinned-event-border-color)
    );
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: var(--border-size);
  animation-play-state: paused;

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: running;
    animation: bg-spin 2s linear infinite;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.cdk-overlay-container a,
.ig-modal-shadow a {
  color: #3476d2;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ig-btn-orange {
  button,
  .mat-mdc-button-touch-target {
    background-color: #ff8834 !important;
  }
}

.ig-btn-gray {
  button,
  .mat-mdc-button-touch-target {
    background-color: lightgray !important;
  }

  .mdc-button__label {
    color: black;
  }
}

.ig-success-snackbar {
  --mdc-snackbar-container-color: #96d196;
  --mdc-snackbar-supporting-text-color: white;
  --mat-snack-bar-button-color: white;
  --mdc-snackbar-supporting-text-weight: 600;
  --mdc-text-button-label-text-weight: 600;

  button {
    .mat-mdc-button-touch-target {
      background-color: transparent !important;
    }
  }
}

simple-snack-bar,
mat-datepicker-content {
  button.mdc-button {
    .mat-mdc-button-touch-target {
      background-color: transparent;
    }
  }
}

mat-icon {
  font-family: "Material Icons" !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: white !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.max-lines-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.max-lines-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.max-lines-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.ig-accordion-menu {
  // bottom dash line of the dropdown menu
  .mat-mdc-menu-item.mdc-list-item:not(:last-child) {
    border-bottom: 1px dashed lightgray;
  }

  // the bottom part of the date range picker
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  .mat-mdc-menu-content {
    max-height: 536px;
  }
}

.ig-date-filter {
  min-width: 208px !important;
}

.ig-date-range-picker {
  .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
    margin-top: 0 !important;
  }
}

.ig-review-sort {
  .mat-mdc-menu-content {
    button {
      padding: 0 16px;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.ig-tb-more {
  ig-search-field
    .mat-mdc-form-field
    .mat-mdc-text-field-wrapper
    .mat-mdc-floating-label {
    left: 0;
  }

  ig-search-field
    .mat-form-field-appearance-fill
    .mat-mdc-form-field-infix
    input {
    left: 0;
  }
}

.animate-out-bottom-up {
  position: relative;
  -webkit-animation-name: animateoutbottom;
  -webkit-animation-duration: 0.4s;
  animation-name: animateoutbottom;
  animation-duration: 0.4s;
}

@-webkit-keyframes animateoutbottom {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: 100px;
    opacity: 0;
  }
}

@keyframes animateoutbottom {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: 100px;
    opacity: 0;
  }
}

.animate-out-bottom-up-300 {
  position: relative;
  -webkit-animation-name: animateoutbottomshort;
  -webkit-animation-duration: 0.3s;
  animation-name: animateoutbottomshort;
  animation-duration: 0.3s;
}

@-webkit-keyframes animateoutbottomshort {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: 50px;
    opacity: 0;
  }
}

@keyframes animateoutbottomshort {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: 50px;
    opacity: 0;
  }
}

.animate-out-up-bottom {
  position: relative;
  -webkit-animation-name: animateoutup;
  -webkit-animation-duration: 0.4s;
  animation-name: animateoutup;
  animation-duration: 0.4s;
}

@-webkit-keyframes animateoutup {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -50px;
    opacity: 0;
  }
}

@keyframes animateoutup {
  from {
    bottom: 0;
    opacity: 1;
  }
  to {
    bottom: -50px;
    opacity: 0;
  }
}

.animate-bottom-up {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 0.4s;
  animation-name: animatebottom;
  animation-duration: 0.4s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.animate-up-bottom {
  position: relative;
  -webkit-animation-name: animateup;
  -webkit-animation-duration: 0.4s;
  animation-name: animateup;
  animation-duration: 0.4s;
}

@-webkit-keyframes animateup {
  from {
    bottom: 50px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animateup {
  from {
    bottom: 50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.animate-left-in {
  position: relative;
  -webkit-animation-name: animateleftin;
  -webkit-animation-duration: 0.4s;
  animation-name: animateleftin;
  animation-duration: 0.4s;
}

@-webkit-keyframes animateleftin {
  from {
    left: -50px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

@keyframes animateleftin {
  from {
    left: -50px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

.animate-right-in {
  position: relative;
  -webkit-animation-name: animaterightin;
  -webkit-animation-duration: 0.4s;
  animation-name: animaterightin;
  animation-duration: 0.4s;
}

@-webkit-keyframes animaterightin {
  from {
    right: -50px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

@keyframes animaterightin {
  from {
    right: -50px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.ig-fade-in {
  animation-delay: 0s;
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ig-fade-out-300 {
  animation-delay: 0s;
  animation-name: fade-out;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ig-shake {
  animation-delay: 0s;
  animation-name: shock;
  animation-duration: 0.1s;
  animation-iteration-count: 3;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes shock {
  0% {
    margin-left: 0px;
    margin-right: 5px;
    /*margin-top: 0px;*/
  }
  100% {
    margin-left: 5px;
    margin-right: 0px;
    /*margin-top: 5px;*/
  }
}

@media (max-width: 1080px) {
  .ig-trip-share-modal {
    mat-dialog-container {
      .mdc-dialog__container,
      .mdc-dialog__surface {
        padding: 0;
      }
    }
  }

  .ig-modal-w-full {
    mat-dialog-container {
      background-color: white;

      .mat-mdc-dialog-surface {
        background-color: white;
        overflow-x: hidden;
      }

      ig-button {
        width: 80px;
      }

      // commented out for mobile fancy event details view, Get Directions button
      // .mdc-button__label {
      //   font-size: 12px;
      // }
      button {
        padding: 2px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}

.ig-default-btn-color {
  background-color: var(--default-btn-background-color) !important;
}

.ig-loading-dot {
  display: block;
  position: relative;
  height: 50px;
}

.ig-loading-dot .ig-loading-dot-wrap {
  position: relative;
  left: -6px;
}

.ig-loading-dot .ig-loading-dot-wrap div {
  position: absolute;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #adadad;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ig-loading-dot .ig-loading-dot-wrap div:nth-child(1) {
  left: 8px;
  animation: ig-loading-dot1 0.6s infinite;
}

.ig-loading-dot .ig-loading-dot-wrap div:nth-child(2) {
  left: 8px;
  animation: ig-loading-dot2 0.6s infinite;
}

.ig-loading-dot .ig-loading-dot-wrap div:nth-child(3) {
  left: 32px;
  animation: ig-loading-dot2 0.6s infinite;
}

.ig-loading-dot .ig-loading-dot-wrap div:nth-child(4) {
  left: 56px;
  animation: ig-loading-dot3 0.6s infinite;
}

@keyframes ig-loading-dot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ig-loading-dot3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ig-loading-dot2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// // over aspect ratio will trigger
// @media (min-aspect-ratio: 16 / 9) {
// }
// @media (orientation: portrait) {
// }
// // https://caniuse.com/?search=range%20media
// @media (100px <= width <= 300px) {
// }

// // define variable
// @custom-media --small (width < 400px);
// @media (--small) {
// }
